import "@css/app.pcss";
import "lazysizes";

import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import collapse from "@alpinejs/collapse";
import htmx from "htmx.org";

import Carousel from "./carousel";
import Swiper from "./hslider";
import NumberSelect from "./NumberSelect";
import SortDropdown from "./SortDropdown";
import AddressFinder from "./AddressFinder";
import adjustScrollHeight from "./adjustScrollHeight.ts";
import UpdateStore from "./UpdateStore";
import LineItem from "./LineItem";
import Payment from "./Payment";
import URLUpdater from "./URLUpdater";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";

window.Alpine = Alpine;
window.htmx = htmx;

// Disable and body scroll locks on mount
clearAllBodyScrollLocks();

Alpine.plugin(persist);
Alpine.plugin(collapse);

// Carousel Component
Alpine.data("Carousel", Carousel);
Alpine.data("SwiperHeader", Swiper);
Alpine.data("SwiperHeaderText", Swiper);
Alpine.data("SwiperManagerSection", Swiper);
Alpine.data("BrandsCarousel", Swiper);
Alpine.data("ProductImageCarousel", Swiper);
Alpine.data("PromoCarousel", Swiper);
Alpine.data("NumberSelect", NumberSelect);
Alpine.data("SortDropdown", SortDropdown);
Alpine.data("AddressFinder", AddressFinder);
Alpine.data("UpdateStore", UpdateStore);
Alpine.data("LineItem", LineItem);
Alpine.data("Payment", Payment);
Alpine.data("URLUpdater", URLUpdater);
Alpine.data("adjustScrollHeight", adjustScrollHeight);

// Setup stores
Alpine.store("addresses", {
    shipping_suburb: Alpine.$persist(""),
    shipping_address: Alpine.$persist(""),
    shipping_city: Alpine.$persist(""),
    shipping_postalCode: Alpine.$persist(""),
    billing_suburb: Alpine.$persist(""),
    billing_address: Alpine.$persist(""),
    billing_city: Alpine.$persist(""),
    billing_postalCode: Alpine.$persist(""),
});

Alpine.start();

htmx.on("htmx:afterSwap", function (event) {
    const target = event.target as HTMLElement;

    //check if target has a direct child with id of shop
    const shop = target.querySelector("#shop-items");

    if (target && shop) {
        shop.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
});
