declare var htmx: any;

export default function () {
    return {
        loadingData: false,
        overrideCartAddress: this.$persist(false),
        selectedShippingMethod: this.$persist("freight"),
        async updateStore(event: any, csrfToken: string, siteUrl: string) {
            // only update if there is a this.$store.addresses.shipping_address

            if (!this.$store.addresses.shipping_address) {
                return;
            }

            this.loadingData = true;

            // Update cart with new address
            this.updateCart(siteUrl, csrfToken);
        },

        async handleUpdateAddress(
            event: any,
            csrfToken: string,
            siteUrl: string
        ) {
            this.loadingData = true;

            const addressData = await JSON.parse(event.detail.address);

            if (event.detail.addressType === "shipping") {
                // Change store values to match address data
                this.$store.addresses.shipping_address =
                    addressData.addressLine1;
                this.$store.addresses.shipping_suburb =
                    addressData.dependentLocality;
                this.$store.addresses.shipping_postalCode =
                    addressData.postalCode;
                this.$store.addresses.shipping_city = addressData.locality;
            } else if (event.detail.addressType === "billing") {
                // Change store values to match address data
                this.$store.addresses.billing_address =
                    addressData.addressLine1;
                this.$store.addresses.billing_suburb =
                    addressData.dependentLocality;
                this.$store.addresses.billing_postalCode =
                    addressData.postalCode;
                this.$store.addresses.billing_city = addressData.locality;
            }

            // Update cart with new address
            this.updateCart(siteUrl, csrfToken);
        },
        async updateCart(siteUrl, csrfToken) {
            const data = [
                "action=update-cart",
                `&shippingAddress[addressLine1]=${this.$store.addresses.shipping_address}`,
                `&shippingAddress[dependentLocality]=${this.$store.addresses.shipping_suburb}`,
                `&shippingAddress[postalCode]=${this.$store.addresses.shipping_postalCode}`,
                `&shippingAddress[locality]=${this.$store.addresses.shipping_city}`,
                `&shippingAddress[countryCode]=NZ`,
                `&billingAddress[addressLine1]=${this.$store.addresses.billing_address}`,
                `&billingAddress[dependentLocality]=${this.$store.addresses.billing_suburb}`,
                `&billingAddress[postalCode]=${this.$store.addresses.billing_postalCode}`,
                `&billingAddress[locality]=${this.$store.addresses.billing_city}`,
                `&billingAddress[countryCode]=NZ`,
                `&fields[storeForOrder][]=${this.$refs.storeForOrder.value}`,
                `&shippingMethodHandle=${this.selectedShippingMethod}`,
                `&CRAFT_CSRF_TOKEN=${csrfToken}`,
            ];

            const query = data.join("");

            // Ajax event to update address using address data in alpine store
            const response = await fetch(
                `${siteUrl}actions/commerce/cart/update-cart`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: query,
                }
            );

            if (response) {
                // If the response is successful, reload sprig component.
                htmx.trigger("#checkoutForm", "refresh");

                htmx.onLoad(() => {
                    this.loadingData = false;
                });
            }
        },

        async updateFreight(
            csrfToken: string,
            siteUrl: string,
            method: string
        ) {
            this.loadingData = true;

            const data = [
                "action=update-cart",
                `&shippingMethodHandle=${method}`,
                `&CRAFT_CSRF_TOKEN=${csrfToken}`,
            ];

            const query = data.join("");

            // Ajax event to update address using address data in alpine store
            const response = await fetch(
                `${siteUrl}actions/commerce/cart/update-cart`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: query,
                }
            );

            if (response) {
                // If the response is successful, reload sprig component.
                htmx.trigger("#checkoutForm", "refresh");

                htmx.onLoad(() => {
                    this.loadingData = false;
                });
            }
        },
    };
}
