import {
    Swiper,
    Autoplay,
    EffectFade,
    EffectCoverflow,
    Pagination,
} from "swiper";
import "swiper/css";
import "swiper/css/pagination";

type Direction = "horizontal" | "vertical";
type Effect =
    | "slide"
    | "fade"
    | "cube"
    | "coverflow"
    | "flip"
    | "creative"
    | "cards";

interface SlideSettings {
    slidesPerView?: number;
    spaceBetween?: number;
}
interface Breakpoints {
    [key: number | string]: SlideSettings;
}

Swiper.use([Autoplay, EffectFade, EffectCoverflow, Pagination]);

export default () => ({
    autoPlayDuration: 5000,
    defaultSpeed: 300,
    currentSlide: 0,
    swiper: null,
    start(settings: {
        autoplay: boolean;
        duration: number;
        ref: any;
        direction: Direction;
        speed: number;
        effect: Effect;
        slidesPerView: number;
        spaceBetween: number;
        breakpoints: Breakpoints;
        loop: boolean;
    }): void {
        this.swiper = new Swiper(settings.ref, {
            effect: settings.effect ? settings.effect : "slide",
            fadeEffect: { crossFade: true },
            speed: settings.speed ? settings.speed : this.defaultSpeed,
            loop: settings.loop ? settings.loop : false,
            // noSwiping: true,
            freeMode: true,
            direction: settings.direction ? settings.direction : "horizontal",
            slidesPerView: settings.slidesPerView ? settings.slidesPerView : 1,
            spaceBetween: settings.spaceBetween ? settings.spaceBetween : 0,
            breakpoints: settings.breakpoints ? settings.breakpoints : {},
            autoplay: settings.autoplay
                ? {
                      delay: settings.duration
                          ? settings.duration
                          : this.autoPlayDuration,
                      disableOnInteraction: false,
                  }
                : false,
            observer: true,
            observeParents: true,
            pagination: {
                el: this.$refs.pagination,
                type: "bullets",
            },
        });

        this.currentSlide = this.swiper.activeIndex;

        this.swiper.on("slideChange", () => {
            this.currentSlide = this.swiper.activeIndex;
        });
        if (settings.autoplay) {
            setTimeout(() => {
                this.swiper.autoplay.start();
            }, 2000);
        }
    },
    setCurrentSlide(index: number): void {
        // wrap around max number of slides
        if (index > this.swiper.slides.length - 1) {
            index = 0;
        } else if (index < 0) {
            index = this.swiper.slides.length - 1;
        }
        this.currentSlide = index;
        this.swiper.slideTo(index);
    },
});
