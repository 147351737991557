import { toggleClass } from "htmx.org";

export default () => ({
  expanded: false,
  atBottom: false,
  init() {
    // only show x-ref showMoreButton if the content is overflowing
    const showMoreButton = this.$refs.showMoreButton;
    const content = this.$refs.content;

    this.$nextTick(() => {

      // get the height of .expand-list which is a child of content
      const expandList = content.querySelector('.expand-list');


      // if the height of the content is greater than the height of the expand-list
      if (expandList && content && expandList.scrollHeight < content.clientHeight) {
        showMoreButton.style.display = 'none';

        // set the height of the content to the height of the expand-list
        content.style.height = 'auto';
      }

    });

  },
  isAtBottom(e) {
    this.atBottom = e.target.scrollTop > (e.target.scrollHeight - e.target.clientHeight - 300);
  },
  toggle() {
    this.expanded = !this.expanded;

    const content = this.$refs.content;

    this.$nextTick(() => {
      const expandList = content.querySelector('.expand-list');

      if (!this.expanded && expandList) {
        expandList.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }

    });
  }

});
