declare var google: any;
export default function (
    selectedShippingMethod: string,
    shipping_suburb: string,
    shipping_address: string,
    shipping_city: string,
    shipping_postalCode: string,
    billing_suburb: string,
    billing_address: string,
    billing_city: string,
    billing_postalCode: string
) {
    return {
        showBillingFields: this.$persist(false),
        async init() {
            this.assignFields();

            if (this.$store.addresses.showBillingFields === false) {
                this.$store.addresses.billing_address = this.shipping_address;
                this.$store.addresses.billing_suburb = this.shipping_suburb;
                this.$store.addresses.billing_postalCode =
                    this.shipping_postCode;
                this.$store.addresses.billing_city = this.shipping_city;
            }

            this.assignAddressFieldsIfApplyBilling();

            // watch showBillingFields for changes, if true, copy shipping address to billing address
            this.$watch("showBillingFields", () => {
                this.assignAddressFieldsIfApplyBilling();
            });

            // Recursively check if google is loaded
            if (typeof window.google === "undefined") {
                document.addEventListener("google:init", () => {
                    this.initAutocomplete();
                });
            } else {
                this.initAutocomplete();
            }
        },
        initAutocomplete() {
            //get all elements with addressFinderInput
            const addressFinderInputs = document.getElementsByClassName(
                "addressFinderInput"
            ) as HTMLCollectionOf<HTMLInputElement>;

            // loop through all elements with addressFinderInput
            for (let i = 0; i < addressFinderInputs.length; i++) {
                const addressFinderInput = addressFinderInputs[i];

                // get id of element
                const id = addressFinderInput.id;

                if (!addressFinderInput) {
                    return;
                }

                // Billing Address
                let addressFinder = new google.maps.places.Autocomplete(
                    addressFinderInput,
                    {
                        componentRestrictions: { country: ["nz"] },
                        fields: ["address_components"],
                        types: ["address"],
                    }
                ) as any;

                // prevent default on keydown
                addressFinderInput.addEventListener("keydown", (e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                    }
                });

                addressFinder.addListener("place_changed", () => {
                    // We are using shipping fields
                    const place = addressFinder.getPlace();
                    const address_components = place.address_components;

                    let connectedAddress = "";

                    /* ------------------------- DELIVERY ------------------------- */
                    if (id === "shippingAddressFinder") {
                        for (const key in address_components) {
                            switch (address_components[key].types[0]) {
                                case "street_number":
                                    connectedAddress +=
                                        address_components[key].long_name;
                                    break;
                                case "route":
                                    connectedAddress +=
                                        " " + address_components[key].long_name;
                                    break;
                                case "sublocality_level_1":
                                    this.$store.addresses.shipping_suburb =
                                        address_components[key].long_name;
                                    break;
                                case "locality":
                                    this.$store.addresses.shipping_city =
                                        address_components[key].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    this.$store.addresses.shipping_city =
                                        address_components[key].long_name;
                                    break;
                                case "postal_code":
                                    this.$store.addresses.shipping_postalCode =
                                        address_components[key].long_name;
                                    break;
                                default:
                                    break;
                            }
                        }

                        this.$store.addresses.shipping_address =
                            connectedAddress;
                        /* ------------------------- PICKUP ------------------------- */
                    } else {
                        for (const key in address_components) {
                            switch (address_components[key].types[0]) {
                                case "street_number":
                                    connectedAddress +=
                                        address_components[key].long_name;
                                    break;
                                case "route":
                                    connectedAddress +=
                                        " " + address_components[key].long_name;
                                    break;
                                case "sublocality_level_1":
                                    this.$store.addresses.billing_suburb =
                                        address_components[key].long_name;
                                    break;
                                case "locality":
                                    this.$store.addresses.billing_city =
                                        address_components[key].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    this.$store.addresses.billing_city =
                                        address_components[key].long_name;
                                    break;
                                case "postal_code":
                                    this.$store.addresses.billing_postalCode =
                                        address_components[key].long_name;
                                    break;
                                default:
                                    break;
                            }
                        }

                        this.$store.addresses.billing_address =
                            connectedAddress;
                    }
                });
            }
        },
        assignFields() {
            this.$store.addresses.shipping_suburb = this.$store.addresses
                .shipping_suburb.length
                ? this.$store.addresses.shipping_suburb
                : shipping_suburb;

            this.$store.addresses.shipping_address = this.$store.addresses
                .shipping_address.length
                ? this.$store.addresses.shipping_address
                : shipping_address;
            this.$store.addresses.shipping_city = this.$store.addresses
                .shipping_city.length
                ? this.$store.addresses.shipping_city
                : shipping_city;
            this.$store.addresses.shipping_postalCode = this.$store.addresses
                .shipping_postalCode.length
                ? this.$store.addresses.shipping_postalCode
                : shipping_postalCode;
            this.$store.addresses.billing_suburb = this.$store.addresses
                .billing_suburb.length
                ? this.$store.addresses.billing_suburb
                : billing_suburb;
            this.$store.addresses.billing_address = this.$store.addresses
                .billing_address.length
                ? this.$store.addresses.billing_address
                : billing_address;
            this.$store.addresses.billing_city = this.$store.addresses
                .billing_city
                ? this.$store.addresses.billing_city
                : billing_city;
            this.$store.addresses.billing_postalCode = this.$store.addresses
                .billing_postalCode
                ? this.$store.addresses.billing_postalCode
                : billing_postalCode;
        },
        assignAddressFieldsIfApplyBilling() {
            if (this.showBillingFields === false) {
                this.$store.addresses.billing_address =
                    this.$store.addresses.shipping_address;
                this.$store.addresses.billing_suburb =
                    this.$store.addresses.shipping_suburb;
                this.$store.addresses.billing_postalCode =
                    this.$store.addresses.shipping_postalCode;
                this.$store.addresses.billing_city =
                    this.$store.addresses.shipping_city;
            } else {
                this.$store.addresses.billing_address = "";
                this.$store.addresses.billing_suburb = "";
                this.$store.addresses.billing_postalCode = "";
                this.$store.addresses.billing_city = "";
            }
        },
    };
}
