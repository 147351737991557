export default () => ({
    open: false,
    toggle() {
        if (this.open) {
            return this.close();
        }

        this.$refs.button.focus();

        this.open = true;
    },
    close(focusAfter) {
        if (!this.open) return;

        this.open = false;

        focusAfter && focusAfter.focus();
    },
    // submit(chosenFilter: string) {
    //     // remove current filter from window.location.href
    //     const url = new URL(window.location.href);
    //     url.searchParams.delete("sortby");

    //     // reload page with new filter in url query string
    //     window.location.href = `${url}?sortby=${chosenFilter}`;
    // },
});
