export default function () {
    return {
        queryString: "",
        removequeryString(queryString: string): void {
            // Get current url
            let url = window.location.href;

            //Remove queryString from url
            const newURL = url.replace(
                new RegExp(queryString + "=[^&]*&?", "g"),
                ""
            );

            //push new url to browser
            window.history.pushState({ path: newURL }, "", newURL);
        },
    };
}
