declare global {
    interface Window {
        Stripe: any;
    }
}

export default (publishableKey: string, email: string) => ({
    init() {
        const stripe = window.Stripe(publishableKey);

        const elements = stripe.elements();

        const style = {
            base: {
                color: "#32325d",
                fontFamily: `"Helvetica Neue", Helvetica, sans-serif`,
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": { color: "#aab7c4" },
            },
            invalid: { color: "#fa755a", iconColor: "#fa755a" },
        };

        const card = elements.create("card", { style: style });

        card.mount("#card-element");

        // Handle real-time validation errors from the card Element.
        card.on("change", function (event) {
            const displayError: HTMLDivElement | null = document.getElementById(
                "card-errors"
            ) as HTMLDivElement;
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = "";
            }
        });

        // Handle form submission.
        const form: HTMLFormElement | null = document.getElementById(
            "payment-form"
        ) as HTMLFormElement;

        form?.addEventListener("submit", function (event) {
            event.preventDefault();

            const paymentData = {
                billing_details: {
                    email: email,
                },
            };

            stripe
                .createPaymentMethod("card", card, paymentData)
                .then(function (result) {
                    if (result.error) {
                        // Show the user any errors
                        const errorElement =
                            document.getElementById("card-errors");

                        if (errorElement) {
                            errorElement.textContent = result.error.message;
                        }
                    } else {
                        // Insert the token ID into the form so it gets submitted to the server
                        const form: HTMLFormElement | null =
                            document.getElementById(
                                "payment-form"
                            ) as HTMLFormElement;

                        console.log(result.paymentMethod);
                        const hiddenInput = document.createElement("input");
                        hiddenInput.setAttribute("type", "hidden");
                        hiddenInput.setAttribute(
                            "name",
                            "paymentForm[stripe][paymentMethodId]"
                        ); // Craft Commerce only needs this
                        hiddenInput.setAttribute(
                            "value",
                            result.paymentMethod.id
                        );

                        console.log(result.paymentMethod.id);
                        form.appendChild(hiddenInput);

                        form.submit();
                    }
                });
        });
    },
});
