declare var htmx: any;

export default function (settings: {
    qty: number;
    lineItemId: number;
    csrfToken: string;
    siteUrl: string;
}) {
    return {
        loadingData: false,
        qty: settings.qty,
        async updateLineItemQty() {
            this.loadingData = true;

            const data = [
                "action=update-cart",
                `&lineItems[${settings.lineItemId}][qty]=${this.qty}`,
                `&CRAFT_CSRF_TOKEN=${settings.csrfToken}`,
            ];

            const query = data.join("");

            this.updateCart(query);
        },
        async removeLineItem() {
            this.loadingData = true;

            const data = [
                "action=update-cart",
                `&lineItems[${settings.lineItemId}][remove]=1`,
                `&CRAFT_CSRF_TOKEN=${settings.csrfToken}`,
            ];

            const query = data.join("");

            this.updateCart(query);
        },

        async updateCart(query: string) {
            const response = await fetch(
                `${settings.siteUrl}actions/commerce/cart/update-cart`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: query,
                }
            );

            if (response) {
                htmx.trigger("#lineItems", "refresh");
                htmx.onLoad(() => {
                    this.loadingData = false;
                });
            }
        },

        increment() {
            this.qty++;
        },
        decrement() {
            this.qty > 0 ? this.qty-- : (this.qty = 0);
        },
    };
}
