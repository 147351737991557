export default () => ({
    skip: 1,
    index: 0,
    autoplayDuration: 5000,
    movedSlide: false,
    autoplay(duration: number) {
        if (duration) {
            this.autoplayDuration = duration;
        }

        // Autoplay the carousel to slide on a timer
        const mediaQuery = window.matchMedia(
            "(prefers-reduced-motion: reduce)"
        );
        if (!mediaQuery.matches) {
            setInterval(() => {
                this.movedSlide = true;

                setTimeout(() => {
                    this.movedSlide = false;
                    this.next();
                }, 300);
            }, this.autoplayDuration);
        }
    },
    next() {
        this.index++;
        this.to((current, offset) => current + offset * this.skip);
    },
    prev() {
        this.index--;
        this.to((current, offset) => current - offset * this.skip);
    },
    to(strategy) {
        let slider = this.$refs.slider;
        let current = slider.scrollLeft;
        let offset = slider.firstElementChild.getBoundingClientRect().width;

        if (current >= slider.getBoundingClientRect().width) {
            current = 0;
        }

        // If the slider children are less than the total number of slides reset index
        if (this.index >= slider.querySelectorAll("li").length) {
            this.index = 0;
        }

        let scrollTo = strategy(current, offset) * this.index;

        slider.scrollTo({ left: scrollTo, behavior: "smooth" });
    },
    focusableWhenVisable: {
        "x-intersect:enter"() {
            this.$el.removeAttribute("tabindex");
        },
        "x-intersect:leave"() {
            this.$el.setAttribute("tabindex", "-1");
        },
    },
});
